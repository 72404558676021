import $ from "jquery";
// import Swiper JS

// import AOS from "aos";

$(document).ready(function() {
    /* MATCH HEIGHT EQUAL HEIGHT

                                                                                                                                  $('.matchHeight').matchHeight();
                                                                                                                                   */

    /* SWITCHER
                                                                                                                                  $('.toggle-btn').click(function() {
                                                                                                                                      $(this).parent().toggleClass('active').siblings().removeClass('active');
                                                                                                                                      //$(this).parent().find('.single_switcher__active').toggleClass('active').siblings().removeClass('active');
                                                                                                                                  });
                                                                                                                                   */

    /* AOS ANIMATION FUNCTION

                                                                                                                                  $(function() {
                                                                                                                                      AOS.init();
                                                                                                                                  });
                                                                                                                                   */

    var gformCount = 0;

    // Sélectionner tous les formulaires Gravity Forms
    $(".gform_wrapper form").each(function() {
        gformCount++;
        var currentTabIndex = 1;

        // Réinitialiser les tabindex de chaque champ
        $(this)
            .find("input, select, textarea")
            .each(function() {
                $(this).attr("tabindex", currentTabIndex + gformCount * 100);
                currentTabIndex++;
            });
    });

    $(".accordeon-header").click(function() {
        var $content = $(this).next(".accordeon-content");

        if ($content.is(":visible")) {
            $content.slideUp();
        } else {
            $(".accordeon-content").slideUp();
            $(".action").text("+");
            $(this).find(".action").text("-");
            $content.slideDown();
        }
    });

    $(".faq-question").click(function() {
        $(this).find(".icon").toggleClass("rotate");
    });

    $('a[href^="#"]').on("click", function(event) {
        event.preventDefault();
        var target = $(this.getAttribute("href"));
        if (target.length) {
            $("html, body").animate({
                    scrollTop: target.offset().top,
                },
                1000
            );
        }
    });

    var image = document.getElementsByClassName("plx");
    new simpleParallax(image, {
        scale: 1.3,
        delay: 0.4,
        orientation: "up",
    });

    const swiperT = new Swiper("#tems", {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 100,
        breakpoints: {
            960: {
                slidesPerView: 2,
            },
        },
        autoplay: {
            delay: 5000,
        },
        speed: 1000,
    });
});